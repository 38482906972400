import React, { Component } from "react"; 
import RecentNews from "../widgets/RecentNews"; 
import SideBarAdCard from "../widgets/SideBarAdCard";
import { Helmet } from "react-helmet";




class AboutController extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        ...props
      };
    }
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render(){

        const title = "About";
        const description = "About Us";
        const imageUrl = "https://techlegalonline.com/static/media/logo.978760d800d47dff4309.png"; // Replace with your image URL
        const url = "https://techlegalonline.com/about"; 

        return (<>

          <Helmet>

              {/* Standard meta tags */}
              <title>{title}</title>
              <meta name="description" content={description} />
              
              {/* Open Graph meta tags */}
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={imageUrl} />
              <meta property="og:url" content={url} />
              <meta property="og:type" content="website" />
              
              {/* Twitter meta tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={imageUrl} />
              
        </Helmet>
     
            <div className="home-page-outer">
         
        <div className="row row-two">
          <div className="col-md-9">
             
            <div className="about-wrap">
                <div className="about-row row">
                    <div className="about-col col-md-12">
                    <p >Daily Law Times Private Limited in short (DLTPL) is a Company registered under the Companies Act in the year 2012 vide CIN No. U72900RJ2012PTC037902, leading Web portal dedicated towards making legal information on the Internet available for all in a much convenient way possible. The Company is also registered under Government of India’s initiative for Startups and has been issued Certificate from Department of Industrial Policy and Promotion (DIPP) as Startups.</p>
<p >DLTPL is the only portal committed for the practicing Advocates, paralegal, Counsels, Chartered Accountants, Company Secretaries and Students from all the subjects of Legal and Taxation which also covers day to day adjustment in any subject of law through its most dedicated team. Daily Law Times currently provides legal professionals with a wide range of data such as Reported/Unreported Judgments, Case Laws, Orders, Legislations, Act &amp; Rules, Regulations, Notifications, Gazette, Trade Note, Circulars, Press Releases, Tariff, Manual, Law Reports, Code, Central and State Statutes and Legislations. The news portal aims at providing the Judgments passed by the apex court that is the Supreme Court and all the High Courts of the State on daily basis.</p>
<p >In this dynamic era, being updated has proved to be a necessity and we make it happen with just a simple click by serving with all the latest Judgments, recent legislations including Acts, Rulesand Ordinances and much more from the legal world. Furthermore, DLTPL intends to publish Interviews by the renowned professionals from the legal fraternity alongwith various Articles, Blogs and direct reporting from the Courts.</p>
<p  >With a vision of letting people be updated anytime, anywhere. We’re more than glad to be assisting the people in need of legal information. Stay connected and updated with us.</p>
                        </div> 
                    </div> 
            </div> 
          </div>
          <div className="col-md-3">
            <SideBarAdCard ></SideBarAdCard>
            <RecentNews></RecentNews>
          </div>
        </div>
      </div>
        </>);
    }

}

export default AboutController;