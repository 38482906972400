import React, { Component } from "react";
import ReactPaginate from "react-paginate";

import SearchForm from "./../widgets/SearchForm";
import BrandSlider from "../widgets/BrandSlider";
import NavTabBar from "../widgets/NavTabBar";
import SideBarAdCard from "../widgets/SideBarAdCard";
import RecentNews from "../widgets/RecentNews";
import SearchCard from "../widgets/SearchCard";

import { NewsService } from "./../services/news";

import { AppContext } from "./../config/common";
class NewsTagsController extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
        ...props,
      category: [],
      products: { data: [] },
      news: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };
  }
  componentDidMount() {
    this.fetchProducts();
    this.fetchCategory();
    this.fetchPostByTag();
  }
  fetchProducts = async () => {
    const products = await NewsService.fetchProducts();
    this.setState({ products });
  };
  fetchCategory = async () => {
    const category = await NewsService.fetchCategory();
    this.setState({ category });
  };
  fetchPostByTag = async (page = 1) => {
    const slug = this.state.tagslug;
    const { news, pagination } = await NewsService.fetchPostByTag(
      slug,
      page
    );
    this.setState({ news, pagination });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };

  handlePageClick = (e) => {
    this.fetchPostByTag(parseInt(e.selected ?? 0) + 1);
    window.scrollTo(0, 0);
  };
  render() {
    const { settings } = this.context;
    return (
      <div className="home-page-outer">
        {settings.show_products ? (
          <div className="brand-wrp">
            <div className="slider-wrap slide-desk">
              <BrandSlider
                products={this.state.products.data}
                ismobile={false}
              ></BrandSlider>
            </div>
            <div className="slider-wrap slide-mob">
              <BrandSlider
                products={this.state.products.data}
                ismobile={true}
              ></BrandSlider>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row main-menu">
          <div className="col-md-9">
            <NavTabBar categorys={this.state.category}></NavTabBar>
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two">
          <div className="col-md-9">
            <div className={"row  split-row row-three search-row"}>
              {this.state.news.map((news, idx) => {
                return <SearchCard news={news} key={"search-card-" + idx} />;
              })}
            </div>

            <div className="row paginate-row">
              <ReactPaginate
                breakLabel="..."
                nextLabel="  >"
                onPageChange={this.handlePageClick}
                pageRangeDisplayed={3}
                pageCount={this.state.pagination.pageCount}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard></SideBarAdCard>
            <RecentNews></RecentNews>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsTagsController;
