import BasicUserFrom from './BasicUser';
import LawyerFirmForm from './LawyerFirm';
import  { useState, useContext } from 'react';
import { Link, Navigate } from "react-router-dom";
import { AppContext } from "./../../config/common";
import  { Toaster } from 'react-hot-toast';


const Registration = () =>{
    const [step, setStep] = useState(1);
    const [firstForm, setFirstForm] = useState({});
    const [secondForm, setSecondForm] = useState({});
    const { settings } = useContext(AppContext);
    const setFirstFormData = (data) => {
        setFirstForm(data);
    };
    const setSecondFormData = (data) => {
        setSecondForm(data);
    };

    const setStepData = (data) => {
        setStep(data);
    };
    
    
    return (
        <div className="signInWrapp">
            <div className="signInWrapp">
                  <div className="row row-one signInContainer">
                    <div
                      className={
                        step === 2
                          ? "col-md-6 second-form"
                          : "col-md-4 first-form"
                      }
                    >
                      <div className="signIn-head">
                        <h3>Register</h3>
                        {/* <img src={logo} alt="logo" /> */}
                      </div>
                      {step === 1 && <BasicUserFrom settings={settings} setStep={setStepData} setFormData={setFirstFormData} data={firstForm}/>}
                        {step === 2 && <LawyerFirmForm settings={settings} setStep={setStepData} setFormData={setSecondFormData} data={secondForm} firstForm={firstForm}/>} 
                    <div className="signInFooter">
                        <p>
                            Already have an account? <Link to={"/login"}>Login</Link>
                        </p>
                    </div>
                     </div>
                  </div> 
            </div>
            <Toaster />
        </div>
    )
}

export default Registration;