import React, { useState,useRef  } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./templates/Layout";
import Home from "./templates/Home";
import Contact from "./templates/Contact";
import About from "./templates/About";
import Payment from "./templates/Payment";
import Success from "./templates/Success";
import Cart from "./templates/Cart";
import News from "./templates/News";
import NewsTags from "./templates/NewsTags";
import NewsContent from "./templates/NewsContent";
import Search from "./templates/Search";
import NoPage from "./templates/NoPage";
import ComingSoon from "./templates/ComingSoon";
import Login from "./templates/Login";
import Register from "./templates/Register";
import Product from "./templates/Product";


import { AppContext } from "./config/common";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

 
import ForgotPassword from './templates/ForgotPassword'; 
import Account from './templates/Account';
import Store from './templates/Store';
import StoreItemDetail from './templates/StoreItemDetail';
import ResetPassword from './templates/ResetPassword';
import Header from './templates/Header'; 
import ForwardedHeader from "./templates/Header";


function App() {
  const [settings, setSettings] = useState({});
  const [socialIcons, setSocialIcons] = useState({});

  const [hideSearchForm, setHideSearchForm] = useState(false);  


  const updateSetting = (conf={}) => {  
    const newsetting={
      ...settings,
      ...conf
    }
    
    setSettings(newsetting);
  };
  const updateSocialIcons = (icons={}) => {
    const newicons={
      ...socialIcons,
      ...icons
    }
    setSocialIcons(newicons);
  }; 

  const updateHideSearchForm = (value) => setHideSearchForm(value);

  return (
    <AppContext.Provider value={{settings,updateSetting,hideSearchForm,socialIcons,updateSocialIcons ,updateHideSearchForm}}>
      <BrowserRouter  >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/search" element={<Search />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/payment2/:store/:id/:slug" element={<Payment/>} />
            <Route path="/comming-soon" element={<ComingSoon />} /> 
            <Route path="/products" element={<ComingSoon />} /> 
            <Route path="/catalogs/:store" element={<Store />} />
            <Route path="/catalogs/:store/:id/:slug" element={<StoreItemDetail />} />
            <Route path="/login" element={<Login />} /> 
            <Route path="/account" element={<Account />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/join" element={<ComingSoon />} />
            <Route path="/privacy-policy" element={<ComingSoon />} />
            <Route path="/terms-of-service" element={<ComingSoon />} />
            <Route path="/career" element={<ComingSoon />} />
            <Route path="/subscribe" element={<ComingSoon />} /> 
            <Route path="/news/:catslug" element={<News />} />
            <Route path="/tags/:tagslug" element={<NewsTags />} />
            <Route path="/:catslug/:newsslug" element={<NewsContent />} /> 
            <Route path="/:newsslug" element={<NewsContent />} /> 
            <Route path="/404" element={<NoPage />} />
            <Route path="/*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}



export default App;
