import Catalog from "../Catalog";
import { request } from "./../config/request" 

export const PaymentService = {
    async verifyPayment(data){
        const verify=await request.post('/api/payment/verify',data).then(async (response)=>{ 
            if(response.status===200){
                return true;
            }
            return false;
        })

        return verify;
    },
    async checkoutCart(data){
        if(Catalog.Session.isLogined){
            const payment=await request.post('/api/payment/checkout',{
                user_id: Catalog.Session.user.id,
                receipt:Catalog.Cart.id,
                data:data
              }).then(async (response)=>{ 
                console.log(response);
                if(response.status===200){
                    const data = await response.data;
                    return data; 
                }  
                return {
                    error:"try again"
                };
            }).catch ((error) =>{  
                return {
                    error:error
                }
            })        
            return payment;
        }else{
            const payment=await request.post('/api/payment/checkout',{
                receipt:Catalog.Cart.id,
                data:data        
              }).then(async (response)=>{ 
                console.log(response);
                if(response.status===200){
                    const data = await response.data;
                    return data; 
                }  
                return {
                    error:"try again"
                };
            }).catch ((error) =>{  
                return {
                    error:error
                }
            })        
            return payment;
        }
    },
   
    async submitPayment(user_id,book_id,Price) {     
      
        console.log(user_id);
        console.log(book_id);
        console.log(Price);
        // const payment = await fetch('http://localhost:1337/api/payment', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         user_id: user_id,
        //         book_id:book_id,
        //         Price: Price,
               
        //       })
        //   }).then(async (response)=>{ 
        //     if(response.status===200){
        //         const data = await response.json();
        //         return data; 
        //     }  
        //     return {
        //         error:"try again"
        //     };
        // }).catch ((error) =>{  
        //     return {
        //         error:error
        //     }
        // }); 
        const payment=await request.post('/api/payment/order',{
            user_id: user_id,
            book_id:book_id,
            Price: Price,
           
          }).then(async (response)=>{ 
            console.log(response);
            if(response.status===200){
                const data = await response.data;
                return data; 
            }  
            return {
                error:"try again"
            };
        }).catch ((error) =>{  
            return {
                error:error
            }
        })        
        return payment;
    },

    async CreatePayment(user_id,book_id,Price) {     
      
        console.log(user_id);
        console.log(book_id);
        console.log(Price);
      
        const payment=await request.post('/api/payment/order',{
            user_id: user_id,
            book_id:book_id,
            Price: Price,
           
          }).then(async (response)=>{ 
            console.log(response);
            if(response.status===200){
                const data = await response.data;
                return data; 
            }  
            return {
                error:"try again"
            };
        }).catch ((error) =>{  
            return {
                error:error
            }
        })        
        return payment;
    },

    


    async paymentUserMail(data) { 
  
        console.log(data);



                
            const unixTimestamp = data.orderdate;


            const date = new Date(unixTimestamp * 1000); 

            // Format the date
            const formattedDate = new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
            }).format(date);

            console.log(formattedDate); 

        const totalPrice = data.cart.reduce((total, item) => total + item.Price, 0);

        const message = `
                <table width="700" border="0" cellspacing="0" cellpadding="0" style="background-color:#fff; font-family:Arial, Helvetica, sans-serif; font-size:14px; line-height:24px">
                <tr>
                    <td width="43">&nbsp;</td>
                    <td width="618">&nbsp;</td>
                    <td width="39">&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td><img src="https://techlegalonline.com/static/media/logo.978760d800d47dff4309.png"  width="200px"/></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>Hi ${data.name},<br /><br />

                    Your order has been placed successfully. Thank you for shopping with us<br /><br />
                
                    If you have questions about your order, you can email us at support@techlegal.com<br /><br />
                    
                    
                <span style="font-size:30px">Your Order ID:${data.order.razorpay_order_id}</span><br /><br />


                    
                    Placed on ${formattedDate}<br />
                    <br /></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td><table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td width="34%"><strong>Billing Info</strong></td>
                        <td width="32%">&nbsp;</td>
                        <td width="34%"><strong>Shipping Info</strong></td>
                    </tr>
                    <tr>
                        <td> ${data.billingAddress.name}<br />
                        ${data.billingAddress.address}<br />
                        
                </td>
                        <td>&nbsp;</td>
                        <td> ${data.shippingAddress.name}<br />
                       ${data.shippingAddress.address}<br />
                        
                </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    </table></td>
                    <td>&nbsp;</td>
                </tr>
             
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td><table width="100%" border="0" cellspacing="0" cellpadding="0" class="price-table">
                    <tr>
                        <td width="39%"><strong>Items   </strong></td>
                        <td width="36%"><strong>QTY</strong></td>
                        <td width="25%"><strong>Price</strong></td>
                    </tr>


                     ${data.cart
                        .map(
                            (item) => `
                            <tr>
                                <td>${item.Title}</td>
                                <td>1</td>
                                <td>Rs. ${item.Price}</td>
                            </tr>
                        `
                        )
                        .join("")}
                   
                    <tr>
                        <td>&nbsp;</td>
                        <td><strong>Total</strong></td>
                        <td><strong>Rs. ${totalPrice}.00</strong></td>
                    </tr>
                    </table></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td style="font-size:12px; text-align:center">© 2012 - 2024 Daily Law Times Private Limited. All Rights Reserved</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td style="font-size:12px; text-align:center">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                </table>

         <FormCheckGroup
         
            paymentdata={data}
        />
        ` ;
        const messageText=`payment,\n Name :${data.name}  ,\n Email :${data.email}   `;
        const senemail= data.email;
        const sensubject= "Order Confirmed";

        return Promise.all([
            // gustrequest.post(`api/auth/send-email-confirmation`,{
            //     email:data.email
            // }),
            request.post('/api/email',{
                to: senemail,
                subject:sensubject,
                text: messageText,
                html: message
              }).then((response)=>{
                let proData={
                    success:false,
                };
                if(response.status===200){
                    proData={
                        success:true,
                    }; 
                } 
                return proData;
            })
        ])
    },





    async paymentUserMailAdmin(data,settings) { 
  
        console.log(data);



                
            const unixTimestamp = data.orderdate;


            const date = new Date(unixTimestamp * 1000); 

            // Format the date
            const formattedDate = new Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: true,
            }).format(date);

            console.log(formattedDate); 

        const totalPrice = data.cart.reduce((total, item) => total + item.Price, 0);

        const message = `
                <table width="700" border="0" cellspacing="0" cellpadding="0" style="background-color:#fff; font-family:Arial, Helvetica, sans-serif; font-size:14px; line-height:24px">
                <tr>
                    <td width="43">&nbsp;</td>
                    <td width="618">&nbsp;</td>
                    <td width="39">&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td><img src="https://techlegalonline.com/static/media/logo.978760d800d47dff4309.png"  width="200px"/></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>Dear Admin,,<br /><br />

                   A new order has been placed on Techlegalonline. Below are the details of the order:<br /><br />
                                   
                    
                <span style="font-size:30px">Your Order ID:${data.order.razorpay_order_id}</span><br /><br />


                    
                    Placed on ${formattedDate}<br />
                    <br /></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td><table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td width="34%"><strong>Billing Info</strong></td>
                        <td width="32%">&nbsp;</td>
                        <td width="34%"><strong>Shipping Info</strong></td>
                    </tr>
                    <tr>
                        <td> ${data.billingAddress.name}<br />
                        ${data.billingAddress.address}<br />
                        
                </td>
                        <td>&nbsp;</td>
                        <td> ${data.shippingAddress.name}<br />
                       ${data.shippingAddress.address}<br />
                        
                </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    </table></td>
                    <td>&nbsp;</td>
                </tr>
             
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td><table width="100%" border="0" cellspacing="0" cellpadding="0" class="price-table">
                    <tr>
                        <td width="39%"><strong>Items   </strong></td>
                        <td width="36%"><strong>QTY</strong></td>
                        <td width="25%"><strong>Price</strong></td>
                    </tr>


                     ${data.cart
                        .map(
                            (item) => `
                            <tr>
                                <td>${item.Title}</td>
                                <td>1</td>
                                <td>Rs. ${item.Price}</td>
                            </tr>
                        `
                        )
                        .join("")}
                   
                    <tr>
                        <td>&nbsp;</td>
                        <td><strong>Total</strong></td>
                        <td><strong>Rs. ${totalPrice}.00</strong></td>
                    </tr>
                    </table></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td style="font-size:12px; text-align:center">© 2012 - 2024 Daily Law Times Private Limited. All Rights Reserved</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td style="font-size:12px; text-align:center">&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                </table>

         <FormCheckGroup
         
            paymentdata={data}
        />
        ` ;
        const messageText=`payment,\n Name :${data.name}  ,\n Email :${settings.contact_email}   `;
        const senemail= settings.contact_email;
        const sensubject= "Payment Notification"; 

        return Promise.all([
            // gustrequest.post(`api/auth/send-email-confirmation`,{
            //     email:data.email
            // }),
            request.post('/api/email',{
                to: senemail,
                subject:sensubject,
                text: messageText,
                html: message
              }).then((response)=>{
                let proData={
                    success:false,
                };
                if(response.status===200){
                    proData={
                        success:true,
                    }; 
                } 
                return proData;
            })
        ])
    },




}

















