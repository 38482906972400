
import { request } from "./../config/request"
import { API_ENDPOINT } from "./../config/constant"


export const SearchService = {
    async fetchProducts() {        
        const products=await request.get('/api/products?populate=*').then((response)=>{
            let proData=[];
            let pagination=null;
            if(response.status===200){

                for (let pro in response.data.data) {
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.title,
                        link:response.data.data[pro].attributes.link,
                        image:API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url,
                    })
                }
                pagination=response.data.meta.pagination;
            }

            return {
                data:proData,
                pagination:pagination
            };
        }).catch ((error) =>{
            return { data:[], error:error };
        })        
        return products;
    },
    async fetchCategory() {        
        const products=await request.get('/api/categories?filters[show_nav][$eq]=true&filters[posts][id][$notNull]=true&sort[0]=show_order:asc').then((response)=>{
            let proData=[];
            if(response.status===200){
                for (let pro in response.data.data) {
                    proData.push({
                        id:response.data.data[pro].id,
                        title:response.data.data[pro].attributes.name,
                        slug:response.data.data[pro].attributes.slug,
                    })
                }
            }
            return proData;
        }).catch ((error) =>{
            return [];
        })        
        return products;
    },    
    async fetchAds() {        
        const ads=await request.get('/api/site-ads?populate=*').then((response)=>{
            let proData={
                sidebar:[],
                onecol:[],
                twocol:[]
            };
            if(response.status===200){
                for (let pro in response.data.data) {
                    switch (response.data.data[pro].attributes.type) {
                        case "Side Bar":                            
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;                        
                        case "One Column into List":                            
                                proData.onecol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                                                      
                        case "Two Column into List":                            
                                proData.twocol.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    
                        default:
                                                        
                                proData.sidebar.push({
                                    id:response.data.data[pro].id,
                                    title:response.data.data[pro].attributes.title,
                                    youtube:response.data.data[pro].attributes.youtube,
                                    link:response.data.data[pro].attributes.link,
                                    image:response.data.data[pro].attributes.image.data?API_ENDPOINT+response.data.data[pro].attributes.image.data.attributes.url:null,
                                })
                            break;
                    }
                }
            }
            return proData;
        }).catch ((error) =>{
            console.log(error)
            return {
                sidebar:[],
                onecol:[],
                twocol:[]
            };
        })        
        return ads;
    },
     
    async fetchPostBySearch(keyword,category,page=1) { 
        let start = 0;
        let apiLink = `/api/posts?pagination[page]=${page}&pagination[pageSize]=24&populate[image]=*&populate[categories][populate][0]=parent_category&sort[0]=publish_date:desc`;
        // if(category&&category!==""&&category!=="latest"&&category!=="all"&&category!=="trending"){ 
        //     apiLink = `/api/posts?pagination[page]=${page}&pagination[pageSize]=24&populate[image]=*&populate[categories][populate][0]=parent_category&filters[$or][0][categories][slug][$contains]=${category}&filters[$or][1][categories][parent_category][slug][$contains]=${category}&sort[0]=publish_date:desc`;
        //     start=2;
        // }
        // if((category&&category==="trending")||(keyword&&keyword==="trending")){
        //     apiLink += `&filters[$or][${start}][trending][$eq]=true`;
        //     start=start+1;
        // }
        if(keyword&&keyword!==""){
            apiLink+=`&filters[$or][${start}][title][$containsi]=${keyword}&filters[$or][${start+1}][slug][$containsi]=${keyword}&filters[$or][${start+2}][categories][slug][$containsi]=${keyword}&filters[$or][${start+3}][categories][name][$containsi]=${keyword}&filters[$or][${start+4}][categories][parent_category][slug][$containsi]=${keyword}&filters[$or][${start+5}][categories][parent_category][name][$containsi]=${keyword}`
        }
        const posts=await request.get(apiLink).then((response)=>{
            let proData={
                news:[],
                pagination:{
                    page: 1,
                    pageSize: 24,
                    pageCount: 1,
                    total: 0
                }
            };
            if(response.status===200){
                for (let pro in response.data.data) {
                    const news=response.data.data[pro].attributes;
                    news.image=news.image.data?API_ENDPOINT+news.image.data.attributes.url:null
                    news.id=response.data.data[pro].id; 
                    news.categoryname=[];
                    if(news.image===null&&news.youtube){
                        news.image="https://img.youtube.com/vi/"+news.youtube+"/default.jpg"
                    }

                    if(response.data.data[pro].attributes.categories&&response.data.data[pro].attributes.categories.data){
                        for (const key in response.data.data[pro].attributes.categories.data) {
                            const cat = response.data.data[pro].attributes.categories.data[key];
                            let category="";
                            let subcategory=""; 
                            if(cat.attributes.parent_category&&cat.attributes.parent_category.data){ 
                                category=cat.attributes.parent_category.data.attributes.name
                                subcategory=cat.attributes.name 
                            }else{
                                category=cat.attributes.name
                            } 
                            news.categoryname.push({category:category,subcategory:subcategory})
                        } 
                    } 

                    proData.news.push(news);
                }
                proData.pagination=response.data.meta.pagination;
            } 
            return proData;
        }).catch ((error) =>{ 
            return {
                news:[],
                pagination:{
                    page: 1,
                    pageSize: 24,
                    pageCount: 1,
                    total: 0
                }
            };
        })  
        return posts;
    },    
};