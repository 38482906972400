import React, { useEffect, useState, useCallback } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from 'react-select/async';
import { getTypeOfCourts } from "../../services/type-of-court"
import {SiteService} from "../../services/site"
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const LawyerFirmForm = ({setStep, setFormData, data, firstForm, settings}) => {
    const navigate = useNavigate();
   

    const schema = yup.object({
        profileImage: yup.mixed().required().label(firstForm?.role === '5' ? "Profile Image" : "Firm Image"),
        Title: yup.string().required().label(firstForm?.role === '5' ? "Name of the Lawyer" : "Name of the Firm"),
        Identifier: yup.string().required().label("License"),
        DisplayDate: yup.date().required().label(firstForm?.role === '5' ? "Practice Since" : "Established in"),
        phone_number: yup.string().required().label("Phone Number").test('phone-length', 'Phone number must be 10 digits', (value) => {
            return value && /^\d{10}$/.test(value);
        }),
        SubTitle: yup.string().required().label(firstForm?.role === '5' ? "Qualification" : "Sub title or Tagline"),
        email_address: yup.string().email('Invalid email format').required('Email is required'),
        address: yup.string().required('Address is required'),
        website_link: yup.string().required('Website link is required'),
        type_of_court: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("Type of courts"),
        Property1: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("State"),
        Property3: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("District"),
        Property2: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("City"),
        PropertyList1: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("Specialization Area"),
        PropertyList2: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("Courts"),
        PropertyList3: yup.array().of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            })
          )
        //   .required('At least one district is required')
        //   .min(1, 'You must select at least one district')
        //   .transform((value) => {
        //     // Transform the array of objects into an array of values
        //     if (Array.isArray(value)) {
        //       return value.map((item) => item.value);
        //     }
        //     return [];
        //   })
          .label("Spoken languages"),
        accept: yup.bool().oneOf([true], 'Accept Legal Agreement'),
        Detail1: yup.string().required().label("Profile Details"),
    });
    
    const [imagePreview, setImagePreview] = useState(null);

    useEffect(() => {
        if (data?.profileImage) {
          setImagePreview(URL.createObjectURL(data?.profileImage));
        }
    },[data]);
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setValue("profileImage", file);
      if (file) {
        setImagePreview(URL.createObjectURL(file));
      }
    };
    
    

    const { register, handleSubmit, formState: { errors }, reset, watch, getValues, control, setValue } = useForm({
      defaultValues: {
          profileImage: data?.profileImage || null,
          Title: data?.Title || '',
          Identifier: data?.Identifier || '',
          DisplayDate: data?.DisplayDate || null,
          phone_number: data?.phone_number || '',
          SubTitle: data?.SubTitle || '',
          email_address: data?.email_address || '',
          address: data?.address || '',
          website_link: data?.website_link || '',
          type_of_court: data?.type_of_court || [],
          Property1: data?.Property1 || [],
          Property3: data?.Property3 || [],
          Property2: data?.Property2 || [],
          PropertyList1: data?.PropertyList1 || [],
          PropertyList2: data?.PropertyList2 || [],
          PropertyList3: data?.PropertyList3 || [],
          accept:  false,
          Detail1: data?.Detail1 || '',
      },
      resolver: yupResolver(schema),
    });
  
    const onSubmit = async (data) => {
      setFormData(data);
      console.log(data);
      // if(data?.role !== '4'){
      //     setStep(1);
      // }
      SiteService.registerUser(firstForm, settings).then(user=>{
        if(!!data?.profileImage){
          let formData = new FormData();
          formData.append("files", data?.profileImage);
          return SiteService.uploadMedia(formData).then(file => ([user, file]));
        }else{
          return [user, null];
        }
      }).then(([user, file])=>{
        if(!!file){
          data.PrimaryImageId = file[0].id;
        }
        data.users_permissions_user = user.id; 
        data.SubCategory2 = user.role === "5" ? 0 : 1;
        return SiteService.registerLawyer(data);
    }).then(res=>{
        toast.success('Registered successfully');
        navigate('/login')
        return res;
    })
    .catch(err=>{
        toast.error(err?.response?.data?.error?.message);
    })
      
      
      
    };

    const goBack = () =>{
      setStep(1);
      setFormData(getValues());
    }

    const loadTypeOfCourts = (inputValue) => {
      return getTypeOfCourts({
          filters:{
              name:{
                  $contains: inputValue
              }
          },
          pagination:{
            page:1,
            pageSize:100
        }
      }).then(res=>res?.data
        ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
        .map(r=>({
          label:r.attributes?.name,
          value:r.id
      })));
    };

    const loadStates = (inputValue) => {
      
      return SiteService.fetchAllStates({
          filters:{
              name:{
                  $contains: inputValue
              },
              state_districts:{
                  cities :{
                      courts:{
                          type_of_court:{
                              id: {
                                  $in: watch('type_of_court')?.map(r=> +r?.value)
                              }
                          }
                      }
                  }
              }
          },
          pagination:{
            page:1,
            pageSize:100
        }
      }).then(res=>res?.data
        ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
        .map(r=>({
          label:r.attributes?.name,
          value:r.id
      })));
    };

    const loadDistrict =  useCallback(
      (inputValue) => {
      return SiteService.allDistricts({
          filters:{
              name:{
                  $contains: inputValue
              },
              country_state:{
                  id:{
                      $in: watch('Property1')?.map(r=> +r?.value)
                  }
              }
          },
          pagination:{
            page:1,
            pageSize:100
        }
      }).then(res=>res?.data
        ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
        .map(r=>({
          label:r.attributes?.name,
          value:r.id
      })));
    }, [watch('Property1')]);

    const loadCity = useCallback((inputValue) => {
      return SiteService.allCities({
          filters:{
              name:{
                  $contains: inputValue
              },
              state_district:{
                  id:{
                      $in: watch('Property3')?.map(r=> +r?.value)
                  },
                  country_state:{
                      id:{
                          $in: watch('Property1')?.map(r=> +r?.value)
                      }
                  }
              }
          },
          pagination:{
              page:1,
              pageSize:100
          }
      }).then(res=>res?.data
        ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
        .map(r=>({
          label:r.attributes?.name,
          value:r.id
      })));
    }, [watch('Property3'), watch('Property1')]);

    const specializationArea = (inputValue) => {
      return SiteService.fetchAllSpecializations({
          filters:{
              name:{
                  $contains: inputValue
              }
          }
      }).then(res=>res?.data
        ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
        .map(r=>({
          label:r.attributes?.name,
          value:r.id
      })));
    };

    
    const spokenLanguages = (inputValue) => {
      return SiteService.fetchAllLanguages({
          filters:{
              name:{
                  $contains: inputValue
              }
          }
      }).then(res=>res?.data
        ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
        .map(r=>({
          label:r.attributes?.name,
          value:r.id
      })));
    };

  
    const listCourts = useCallback(
      (inputValue) => {
        return SiteService.fetchAllCourts({
          filters: {
            name: {
              $contains: inputValue,
            },
            city: {
              id: {
                $in: watch("Property2")?.map((r) => +r?.value),
              },
              state_district:{
                id: {
                  $in: watch("Property3")?.map((r) => +r?.value),
                },
                country_state:{
                  id: {
                    $in: watch("Property1")?.map((r) => +r?.value),
                  },
                }
              }
            },
            type_of_court: {
              id: {
                $in: watch("type_of_court")?.map((r) => +r?.value),
              },
            },
          },
          pagination:{
              page:1,
              pageSize:100
          }
        }).then((res) =>
          res?.data
          ?.sort((a, b) => a.attributes?.name.localeCompare(b.attributes?.name))
          .map((r) => ({
            label: r.attributes?.name,
            value: r.id,
          }))
        );
      },
      [watch("Property2"), watch("type_of_court"), watch('Property3'), watch("Property1")]
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='signInForm'>
     <div className="input-file">
        <Form.Group className="mb-3" controlId="profileImage">
          <Form.Label>{firstForm?.role === '5' ? "Profile Image" : "Firm Image"}*</Form.Label>
          <Form.Label>
            <InputGroup className="preview-field" hasValidation>
              <input
                type="file"
                id="profileImage"
                {...register('profileImage')}
                accept="image/*"
                onChange={handleImageChange} // Upda
                style={{ display: "none" }}
              />
              {imagePreview && (
                <div>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                  {/* <button type="button" onClick={this.handleRemove}>Remove</button> */}
                </div>
              )}
            </InputGroup>
            <div className="upload-group">
              {errors.profileImage && <Form.Text className="text-danger">{errors.profileImage.message}</Form.Text>}
            </div>
          </Form.Label>
        </Form.Group>
      </div>

    
    
    <div className="input-field">
        <Form.Group className="mb-3"  controlId="formBasicFirstName">
        <Form.Label>{firstForm?.role === '5' ? "Name of the Lawyer" : "Name of the Firm"}*</Form.Label>
        <Form.Control type="text" {...register('Title')} />
        {errors.Title && <Form.Text className="text-danger">{errors.Title.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicLastName">
        <Form.Label>License*</Form.Label>
        <Form.Control type="text" {...register('Identifier')} />
        {errors.Identifier && <Form.Text className="text-danger">{errors.Identifier.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{firstForm?.role === '5' ? "Practice Since" : "Established in" }*</Form.Label>
        <Controller
          control={control}
          name="DisplayDate"
          defaultValue={null}
          render={({ field }) => (
            <DatePicker
            {...field}
            showYearDropdown
              className="form-control"
              scrollableYearDropdown
              yearDropdownItemNumber={150} 
              maxDate={new Date()}
              placeholderText="Practice Since"
              onChange={(date) => field.onChange(date)}
              selected={field.value || null}
              isClearable
              error={errors.DisplayDate?.message}
            /> 
          )}
        />
        {errors.DisplayDate && <Form.Text className="text-danger">{errors.DisplayDate.message}</Form.Text>}
        </Form.Group>
    </div>
     <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicMobile">
        <Form.Label>{firstForm?.role === '5'  ? "Qualification": "Sub title or Tagline"}*</Form.Label>
        <Form.Control type="text" {...register('SubTitle')} />
        {errors.SubTitle && <Form.Text className="text-danger">{errors.SubTitle.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Email Address*</Form.Label>
        <Form.Control type="email" {...register('email_address')} />
        {errors.email_address && <Form.Text className="text-danger">{errors.email_address.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
        <Form.Label>Phone Number*</Form.Label>
        <Form.Control type="tel" {...register('phone_number')} />
        {errors.phone_number && <Form.Text className="text-danger">{errors.phone_number.message}</Form.Text>}
        </Form.Group>
    </div>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formAddress">
        <Form.Label>Address*</Form.Label>
        <Form.Control  {...register('address')} />
        {errors.address && <Form.Text className="text-danger">{errors.address.message}</Form.Text>}
        </Form.Group>
    </div>

 
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formWebsite_link">
        <Form.Label>Website Link*</Form.Label>
        <Form.Control  {...register('website_link')} />
        {errors.website_link && <Form.Text className="text-danger">{errors.website_link.message}</Form.Text>}
        </Form.Group>
    </div>

    <div className="input-field">
          <Form.Group className="mb-3" controlId="formTypeOfCourt">
              <Form.Label>Type Of Courts</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="type_of_court"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect  
                        {...field} cacheOptions defaultOptions loadOptions={loadTypeOfCourts}
                        isMulti={true} />
                    )}
                    />
                  
                  {errors.type_of_court && <Form.Text className="text-danger">{errors.type_of_court.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
        </div>


        <div className="input-field">
          <Form.Group className="mb-3" controlId="formState">
              <Form.Label>State</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="Property1"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect isMulti={true} {...field} cacheOptions defaultOptions loadOptions={loadStates} />
                    )}
                    />
                  
                  {errors.Property1 && <Form.Text className="text-danger">{errors.Property1.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
        </div>

        <div className="input-field">
          <Form.Group className="mb-3" controlId="formDistrict">
              <Form.Label>District</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="Property3"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect key={JSON.stringify(watch("Property1"))}  isMulti={true} {...field} cacheOptions defaultOptions loadOptions={loadDistrict} />
                    )}
                    />
                  
                  {errors.Property3 && <Form.Text className="text-danger">{errors.Property3.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
        </div>


        <div className="input-field">
          <Form.Group className="mb-3" controlId="formCity">
              <Form.Label>City</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="Property2"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect key={JSON.stringify(watch("Property3"))+ JSON.stringify(watch('Property1'))} isMulti={true} {...field} cacheOptions defaultOptions loadOptions={loadCity} />
                    )}
                    />
                  
                  {errors.Property2 && <Form.Text className="text-danger">{errors.Property2.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
        </div>
        
        <div className="input-field">
          <Form.Group className="mb-3" controlId="formCity">
              <Form.Label>Specialization Area</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="PropertyList1"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect isMulti={true} {...field} cacheOptions defaultOptions loadOptions={specializationArea} />
                    )}
                    />
                  
                  {errors.PropertyList1 && <Form.Text className="text-danger">{errors.PropertyList1.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
        </div>

        <div className="input-field">
          
          <Form.Group className="mb-3" controlId="formCourts">
              <Form.Label>Courts</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="PropertyList2"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect
                          key={JSON.stringify(watch("Property2")) + JSON.stringify(watch("type_of_court") + JSON.stringify(watch("Property3"))+ JSON.stringify(watch("Property1")))} // Cha
                          defaultOptions
                        //  onMenuOpen={handleMenuOpen} 
                          isMulti={true} {...field} cacheOptions loadOptions={listCourts} />
                      )}
                    />
                  {errors.PropertyList2 && <Form.Text className="text-danger">{errors.PropertyList2.message}</Form.Text>}
              </InputGroup>
          </Form.Group>
        </div>

        <div className="input-field">
          <Form.Group className="mb-3" controlId="formSpokenLang">
              <Form.Label>Spoken languages</Form.Label>
              <InputGroup hasValidation>
              <Controller
                    name="PropertyList3"
                    control={control}
                    render={({ field }) => (
                        <AsyncSelect isMulti={true} {...field} cacheOptions defaultOptions loadOptions={spokenLanguages} />
                    )}
                    />
                  {errors.PropertyList3 && <Form.Text className="text-danger">{errors.PropertyList3.message}</Form.Text>}
              </InputGroup>
          </Form.Group> 
        </div>
    
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formWebsite_link">
        <Form.Label>Profile Detail*</Form.Label>
        <Form.Control as="textarea"  {...register('Detail1')} />
        {errors.Detail1 && <Form.Text className="text-danger">{errors.Detail1.message}</Form.Text>}
        </Form.Group>
    </div>
    <br/>
    <div className="input-field">
        <Form.Group className="mb-3" controlId="formBasicUserType">
        <Form.Check value={true} id="userType_agreement" type="checkbox" label="Legal Agreement" {...register('accept')}  />
        {errors.accept && <Form.Text className="text-danger">{errors.accept.message}</Form.Text>}
        </Form.Group>
    </div>
  
    <div className="formfield">
          <Button
            variant="outline-primary"
            className="signInBtn2"
            type="reset"
            onClick={() => goBack()}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className="signInBtn1"
            type="submit"
            
          >
            Register
          </Button>
        </div>
  </Form>
  );
};

export default LawyerFirmForm;