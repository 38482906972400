import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";

import SearchForm from "./../widgets/SearchForm";
import BrandSlider from "../widgets/BrandSlider";
import NavTabBar from "../widgets/NavTabBar";
import SideBarAdCard from "../widgets/SideBarAdCard";
import RecentNews from "../widgets/RecentNews";
import SearchCard from "../widgets/SearchCard";
import { APP_ENDPOINT } from "../config/constant";

import { NewsService } from "./../services/news";

import { AppContext } from "./../config/common";
class NewsController extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      products: { data: [] },
      news: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
      oldslug:null,
      ...props,
    };
  }
  static getDerivedStateFromProps(props, state) { 
      return {
          ...props,
      };
  }
  componentDidMount() {
    this.fetchProducts();
    this.fetchCategory();
  }

  componentDidUpdate(){
    if(this.state.catslug){
      if(!this.state.oldslug||this.state.oldslug!==this.state.catslug){
        this.fetchPostByCategory();
      }
    }
  }
  fetchProducts = async () => {
    const products = await NewsService.fetchProducts();
    this.setState({ products });
  };
  fetchCategory = async () => {
    const category = await NewsService.fetchCategory();
    this.setState({ category });
  };
  fetchPostByCategory = async (page = 1) => {
    const oldslug = this.state.catslug;
    console.log('log test',this.state);
    this.setState({ oldslug });
    window.scrollTo(0, 0);
    const { news, pagination } = await NewsService.fetchPostByCategory(
      oldslug,
      page
    );
    this.setState({ news, pagination });
  };
  handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("Form submitted:");
  };

  handlePageClick = (e) => {
    this.fetchPostByCategory(parseInt(e.selected ?? 0) + 1);
  };
  render() {
    const { settings } = this.context;

    const category = 
                      this.state.category && this.state.category.length > 0
                          ? this.state.category.filter(k => k.slug === this.state.catslug)[0] ?? {}
                          : {};

    const title = category.title || '';
    const description = category.description || '';
    const keywords = category.keywords || '';
    const headingTitle = category.heading_title || `India's leading Legal and Taxation News Portal`;

    const imageUrl = "https://techlegalonline.com/static/media/logo.978760d800d47dff4309.png";

    const url =  category.slug || '';
    return (
      <>
     
      
      <Helmet>
      <title>
          {title ? `${title} | ${headingTitle}` : headingTitle}
      </title>

      <meta name="description" content={description} />

      <meta name="keywords" content={keywords} />

              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={imageUrl} />
              <meta property="og:url" content={APP_ENDPOINT + "/news/" + url} />
              <meta property="og:type" content="website" />
              
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={imageUrl} />

          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4260577672934007"
                  crossorigin="anonymous"></script>

      </Helmet>
      <div className="home-page-outer">

      <h1>{ this.state.category&&this.state.category.length>0?(this.state.category.filter((k)=>{ return k.slug === this.state.catslug;})[0]??{}).title :""}</h1>

        {settings.show_products &&this.state.catslug&&this.state.oldslug&&this.state.oldslug===this.state.catslug? (
          <div className="brand-wrp">
            <div className="slider-wrap slide-desk">
              <BrandSlider
                products={this.state.products.data}
                ismobile={false}
              ></BrandSlider>
            </div>
            <div className="slider-wrap slide-mob">
              <BrandSlider
                products={this.state.products.data}
                ismobile={true}
              ></BrandSlider>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row main-menu">
          <div className="col-md-9">
           {this.state.oldslug===this.state.catslug ?(<NavTabBar categorys={this.state.category} active={this.state.catslug} ></NavTabBar>):(<></>)}
          </div>
          <div className="col-md-3">
            <div className="search-news">
              <SearchForm
                placeholder={"Search News"}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="row row-two">
          <div className="col-md-9">
            <div className={"row  split-row row-three search-row"}>
              {this.state.news.map((news, idx) => {
                return <SearchCard news={news} key={"search-card-" + idx} active={this.state.catslug} />;
              })}
            </div>

            <div className="row paginate-row">
              <ReactPaginate
                breakLabel="..."
                nextLabel="  >"
                onPageChange={this.handlePageClick}
                pageRangeDisplayed={3}
                pageCount={this.state.pagination.pageCount}
                previousLabel="< "
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
          <div className="col-md-3">
            <SideBarAdCard></SideBarAdCard>
            <RecentNews></RecentNews>
          </div>
        </div>
      </div>

      </>
    );
  }
}

export default NewsController;
